<template>
  <section class="tables">

    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Modifier L'objectif
               <router-link to="/objectifs">
              <span class="float-right btn btn-info">
                Retour
              </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="updatobjectif()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Titre <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="objectif.nomperiode"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Délégué <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="objectif.user_id"
                      >
                        <option
                          v-for="user in users"
                          :key="user._id"
                          v-bind:value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Date début <span class="obligatoire">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="objectif.date_debut"
                      />
                      <span class="text-danger" v-if="error != null">
                        {{ error }}
                      </span>
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Date fin <span class="obligatoire">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        id="last"
                        v-model="objectif.date_fin"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Chiffre <span class="obligatoire">*</span>
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="objectif.chiffre"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Nombre de commande
                        <span class="obligatoire">*</span></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        id="last"
                        v-model="objectif.nb_visites"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                >
                  Modifier l'objectif
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import moment from "moment";
import Toast from "sweetalert2";

export default {
  data() {
    return {
      objectif: {},
      users: [],
      error: "",
    };
  },

  created() {
    this.getusers();
    this.oneobjectifs();
  },
  methods: {
    getusers() {
      HTTP
        .get("getusers")
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },

    oneobjectifs() {
      HTTP
        .get("objectifs/oneobjectifs/" + this.$route.params.id)
        .then((response) => {
          console.log(response.data);
          this.objectif = response.data;
          this.objectif.user_id = this.objectif.user_id["_id"];
          this.objectif.date_fin = moment(this.objectif.date_fin).format(
            "YYYY-MM-DD"
          );
          this.objectif.date_debut = moment(this.objectif.date_debut).format(
            "YYYY-MM-DD"
          );
        })
        .then((err) => console.log(err));
    },

    updatobjectif() {
      var date_now = new Date();
      var date1 = new Date(this.objectif.date_debut);
      var date2 = new Date(this.objectif.date_fin);
      if (
        (moment(date1).isBefore(date2) == true ||
          moment(date1).isSame(date2, "day") == true) &&
        (moment(date_now).isBefore(date1) == true ||
          moment(date1).isSame(date_now, "day") == true) &&
        (moment(date_now).isBefore(date2) == true ||
          moment(date2).isSame(date_now, "day") == true)
      ) {
        HTTP
          .put("objectifs/updatobjectif", this.objectif)
          .then((response) => {
            console.log(response);

            if (response.data.type === "erreur") {
              this.error = response.data.title;
            } else {
        Toast.fire({
                        icon: "success",
                        title: "objectif modifié"
           });


        
              this.$router.push("/objectifs");
            }
          });
      } else {
        this.error = "Entrer une date valide";
      }
    },
  },
};
</script>
